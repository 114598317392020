<template>
  <!--
    Footer
    ==================================================
  -->
  <footer class="ftco-footer ftco-bg-dark ftco-section">
    <!-- Main -->
    <div class="container">
      <div class="row mb-2">
        <div class="col-md">
          <div class="ftco-footer-widget mb-4 text-center">
            <a class="navbar-brand" href="/">
              <img
                v-if="info.logo"
                class="footer-logo"
                :src="info.logo"
                alt="logo_web"
              />
              <h5 v-else>{{ info.companyName }}</h5>
            </a>

            <ul
              class="ftco-footer-social list-unstyled float-lft mt-5"
            >
              <li v-if="info.fb != '0'">
                <a class="ftco-animate hov-icon" :href="info.fb"
                  ><span class="icon-facebook-f"></span
                ></a>
              </li>
              <li v-if="info.tw != '0'">
                <a class="ftco-animate hov-icon" :href="info.tw"
                  ><span class=" icon-twitter1 "></span
                ></a>
              </li>
              <li v-if="info.linkedin != '0'">
                <a class="ftco-animate hov-icon" :href="info.linkedin"
                  ><span class="icon-linkedin1"></span
                ></a>
              </li>
              <li v-if="info.pinterest != '0'">
                <a class="ftco- hov-icon" :href="info.pinterest"
                  ><span class="icon-pinterest"></span
                ></a>
              </li>
              <li v-if="info.instagram != '0'">
                <a class="ftco-animate hov-icon" :href="info.instagram"
                  ><span class="icon-instagram"></span
                ></a>
              </li>
              <li v-if="info.youtube != '0'">
                <a class="ftco-animate hov-icon" :href="info.youtube"
                  ><span class="icon-youtube-play"></span
                ></a>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-md">
          <div class="ftco-footer-widget mb-4 ml-md-5 left-menu ">
            <h2 class="ftco-heading-2">{{ $t("layout.footer.menu_title") }}</h2>
            <ul class="list-unstyled list-unstyled-menu">
              <li>
                <router-link to="/" class="py-1 d-block">{{
                  $t("layout.nav.home")
                }}</router-link>
              </li>
              <li>
                <router-link to="/nosotros" class="py-1 d-block">{{
                  $t("layout.nav.about.title")
                }}</router-link>
              </li>
              <li>
                <router-link to="/propiedades" class="py-1 d-block">{{
                  $t("layout.nav.properties")
                }}</router-link>
              </li>
              <!-- <li>
                <router-link to="/desarrollos" class="py-1 d-block"
                  >{{ $t("layout.nav.developments") }}</router-link
                >
              </li> -->
              <li>
                <router-link to="/agentes" class="py-1 d-block"
                  >{{ $t("layout.nav.agents") }}</router-link
                >
              </li>
              <li>
                <router-link to="/contacto" class="py-1 d-block"
                  >{{ $t("layout.nav.contact") }}</router-link
                >
              </li>
            </ul>
          </div>
        </div>

        <div class="col-md">
          <div class="ftco-footer-widget mb-4">
            <h2 class="ftco-heading-2">{{ $t("layout.footer.contact_title") }}</h2>
            <div class="block-23 mb-3">
              <ul>
                <li v-if="info.address">
                  <span class="icon icon-map-marker"></span>
                  <span class="text"> {{ info.address }}</span>
                </li>
                <li v-if="info.phone != '0'">
                  <span class="icon icon-phone"></span
                  ><span class="text"> {{ info.phone }}</span>
                </li>
                <li v-if="info.reserveemail != '0'">
                  <router-link to="/contacto"
                    ><span class="icon icon-envelope"></span
                    ><span class="text">
                      {{ info.reserveemail }}</span
                    ></router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 text-center">
          <div class="copyrights">
            {{ info.companyName }}<sup>&copy;</sup> {{ year }}.
            <!--<router-link to="/aviso-de-privacidad">
              AVISO DE PRIVACIDAD</router-link
            >-->
            <br />
           <router-link to="aviso">Aviso de Privacidad </router-link> | {{ $t("layout.footer.copyright") }}  <br>Powered with <i class="far fa-heart"></i> & <i class="fas fa-mug-hot"></i> by <a href="https://www.immocrm360.com.mx/" target="_blank">IMMO CRM 360<sup>&copy;</sup></a>
          </div>
        </div>
      </div>

      <!--
        <div class="dadFace">

        	<ul class="ftco-footer-social list-unstyled float-md-left float-lft mt-5">
        		<li>
        			<a class="facebook" target="_blank" href="https://www.facebook.com/habifel/?ref=bookmarks">
        				<i class="icon-facebook"></i>
        			</a>
        		</li>
        		<li>
        			<a class="twitter" target="_blank" href="https://twitter.com/intent/tweet?text=https://www.immosystem.com.mx/">
        				<i class="icon-twitter"></i>
        			</a>
        		</li>
        		<li>
        			<a class="pinterest" target="_blank" :href="'https://www.pinterest.com.mx/pin/create/button/?url=https://www.immosystem.com.mx/&media=https://agent.immosystem.com.mx/'+logo+'&description=Venta y renta de casas, departamentos y otros tipos de propiedades. Venta y renta de bienes raíces. Venta y renta de inmuebles : B-HUB'">
        				<i class="icon-pinterest"></i>
        			</a>
        		</li>
        	</ul>
        </div>
      -->
      <!-- Copyright -->
    </div>
  </footer>
  <!-- Footer / End -->
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      year: "",
    };
  },
  created() {
    let d = new Date();
    this.year = d.getFullYear();
  },
  computed: {
    ...mapGetters({
      info: "_getInfoCompany",
    }),
  },
};
</script>

<style scoped>
h5 {
  color: #ffffff;
}
.copyrights {
  color: rgba(255, 255, 255, 0.7);
}
.contact-links {
  font-size: inherit;
  text-transform: none;
}

.footer-logo {
  display: inline-block;
  max-width: 80%;
  margin-top: auto;
}

.footer-logo img {
  margin-top: 40%;
  margin-left: -10px;
  max-width: 165px;
  max-height: 200px;
}

.footer_contact {
  margin: 10px 0;
}
 @media (max-width: 767.98px) {
    .list-unstyled-menu {
      columns: 2; } 
      }
       @media (max-width: 767.98px) {
    .left-menu {
      padding-left: 50px; } 
      }
.hov-icon:hover span{
  color:#ef4b4a !important;
}
</style>
