<template>
  <main>
    <!-- Content ================================================== -->
    <section class="ftco-section ftc-no-pb mb-3">
      <div class="container">
        <div class="row no-gutters">
          <div class="col-md-12 wrap-about ftco-animate fadeInUp ftco-animated">
            <div class="heading-section heading-section-wo-line mb-3">
              <div class="text-center ">
                <h2 class="mb-4">{{$t("pages.about.company.title")}}</h2>
              </div>
            </div>
            <div class="row mb-3 p-4">
                          
            <p class="jump-line" v-if="info.about_us == 0 & info.about_us_eng == 0 || info.about_us == 0">
              {{$t("pages.about.company.description")}}
            </p>
            <p class="jump-line" v-else>
              <About 
                :aboutx="activeLocale == 'es' || !info.about_us_eng
                ? info.about_us	 : info.about_us_eng"
                />
            </p>
            </div>
          <div class="row">
            <div class="col-md-1"></div>
              <div class="post-content  col-md-10" align="center">
                <p class="quote ">

                  <span v-if="info.our_slogan == 0 & info.our_slogan_eng == 0" class="jump-line"
                    > {{$t("pages.about.slogan")}}</span
                  >
                  <span v-else class="jump-line">
                    <Slogan 
                      :sloganx="activeLocale == 'es' || !info.our_slogan_eng
                      ? info.our_slogan	 : info.our_slogan_eng"
                      />
                  </span>
                </p>
              </div>
          </div>
          <br />
            <div class="row mb-3 p-4">
              <div class="row mb-5 mt-3 text-center">
                <div class="col-md-4 ">
                  <h2
                    class="text-center"
                    data-animation-direction="from-left"
                    data-animation-delay="250"
                  >
                    <p class="text-center">{{$t("pages.about.vision.title")}}</p>
                  </h2>
                  <br />
                <p v-if="info.our_vision == 0 & info.our_vision_eng == 0"
                    data-animation-direction="from-left"
                    data-animation-delay="650" class="jump-line text-darker">
                    {{$t("pages.about.vision.description")}}
                </p>
                <p v-else
                    data-animation-direction="from-left"
                    data-animation-delay="650" class="jump-line text-darker">
                  <Vision 
                    :visionx="activeLocale == 'es' || !info.our_vision_eng
                    ? info.our_vision	 : info.our_vision_eng"
                    />
                </p>
                </div>
                <div class="col-md-4">
                  <h2
                    class="text-center"
                    data-animation-direction="from-left"
                    data-animation-delay="250"
                  >
                    <p class="text-center">{{$t("pages.about.mision.title")}}</p>
                  </h2>
                  <br />
                  <p v-if="info.our_mission == 0 & info.our_mission_eng == 0" class="jump-line"
                    data-animation-direction="from-left"
                    data-animation-delay="650">
                      {{$t("pages.about.mision.description")}}
                  </p>
                  <p v-else
                    data-animation-direction="from-left"
                    data-animation-delay="650" class="jump-line">
                    <Mision 
                    :misionx="activeLocale == 'es' || !info.our_mission_eng
                    ? info.our_mission	 : info.our_mission_eng"
                    />
                  </p>
                </div>
                <div class="col-md-4">
                  <h2
                    class="text-center"
                    data-animation-direction="from-left"
                    data-animation-delay="250"
                  >
                    <p class="text-center">{{$t("pages.about.values.title")}}</p>
                  </h2>
                  <br />
                  <p v-if="info.our_values == 0 & info.our_values_eng == 0"
                        data-animation-direction="from-left"
                    data-animation-delay="650" class="jump-line">
                  <ul >
                    <li>
                      {{$t("pages.about.values.example_one.description")}}

                    </li>
                    <li>{{$t("pages.about.values.example_two.description")}}</li>
                    <li>
                      {{$t("pages.about.values.example_three.description")}}

                    </li>
                    <li>{{$t("pages.about.values.example_four.description")}}</li>
                  </ul>
                  </p>
                  <p v-else
                    data-animation-direction="from-left"
                    data-animation-delay="650"  class="jump-line">
                    <Values 
                      :valuesx="activeLocale == 'es' || !info.our_values_eng
                      ? info.our_values	 : info.our_values_eng"
                      />
                  </p>
                </div>
              </div>
              <router-link to="/contacto" class="btn btn-primary py-3 px-5"
                >{{$t("pages.about.contact-button")}}</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import { mapGetters } from "vuex";
import About from "@/components/about/About.vue";
import Slogan from "@/components/about/Slogan.vue";
import Mision from "@/components/about/Mision.vue";
import Vision from "@/components/about/Vision.vue";
import Values from "@/components/about/Values.vue";
import Banner from "@/components/layout/Banner.vue";
export default {
    components: {
      About,
      Slogan,
      Mision,
      Vision,
      Values,
      Banner
  },
  computed: {
    ...mapGetters({
      info: "_getInfoCompany",
    }),
    activeLocale() {
      return this.$i18n.locale;
    },
  },
  created() {
    window.scrollTo(0, 0);
  },
};
</script>
<style scoped>
p {
  text-align: justify;
}
img {
  width: inherit;
}
.btn-primary {
  margin: auto;
}
.jump-line{
  word-wrap: break-word;
  white-space: pre-wrap;
}
.post-content .quote {
  font-size: 19pt;
  font-weight: 300;
  line-height: 21pt;
  margin: 40px 0;
  padding: 40px 60px;
  position: relative;
  text-align: center;
  width: 100%;
  font-family: 'Muli', sans-serif;
}
.post-content .quote::before {
  content: "\201C";
  left: 30px;
  top: 10px;
}
.post-content .quote::after {
  bottom: -16px;
  content: "\201D";
  right: 30px;
}
.post-content .quote::before, .post-content .quote::after {
  color: #138cca;
  font-size: 48pt;
  font-weight: 700;
  position: absolute;
}
.quote .jump-line p{
  text-align: center;
}
</style>
