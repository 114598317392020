<template lang="html">
  <div v-if="!loader">
    <Header />
    <Banner v-if="!$route.meta.hideBanner" :pageTitle="$route.meta.pageTitle" image="/images/bg_1.jpg" />
    <!-- Search -->
    <SearchForm v-if="!$route.meta.hideSearch" />
    <router-view></router-view>
    <Footer />
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">

          <!-- === START ====== -->
          <div v-if="showCookieBanner" class="cookie-banner">
            <div class="alert mb-0 d-lg-flex align-items-center" role="alert">
              <span class="mr-auto"><i class="fa-solid fa-cookie text-warning"></i> Usamos cookies para mejorar su experiencia de navegación, mostrarle contenidos personalizados y analizar el tráfico de nuestra web.  <router-link  class="font-weight-bold text-warning" to="/aviso">Más información</router-link> </span>
              <br class="d-block d-md-none">
              <button class="btn btn-dark mr-3" @click="rejectCookies">Rechazar</button>
              <button class="btn btn-primary"  @click="acceptCookies">Aceptar</button>
            </div>
          </div>
          <!-- === END ====== -->


        </div>
      </div>

    </div>

  </div>
  <Preloader v-else />
</template>

<script>
import Footer from "@/components/layout/Footer.vue";
import Preloader from "@/components/layout/Preloader.vue";
import SearchForm from "@/components/search/SearchForm.vue";
import Header from "@/components/layout/Header.vue";
import Banner from "@/components/layout/Banner.vue";
export default {
  name: "App",
  components: {
    Footer,
    Preloader,
    SearchForm,
    Header,
    Banner,
  },
  data() {
    return {
      loader: true,
      showCookieBanner: false
    };
  },
  mounted() {
    this.checkCookie('CountVisit');
  },
  methods: {
    checkCookie(keyName) {
      let confirmCookie = this.$cookies.get(keyName);

      if (!confirmCookie) {
        this.showCookieBanner = true; // Mostrar el banner de cookies
      }
    },
    acceptCookies() {
      this.createCookie('cookieAccepted', 1, 365);
      this.$store.dispatch('setNewVisit');
      this.showCookieBanner = false; // Ocultar el banner de cookies después de aceptar
    },
    rejectCookies() {
      this.createCookie('cookieRejected', 1, 365); // Puedes establecer una cookie de rechazo
      this.showCookieBanner = false; // Ocultar el banner de cookies después de rechazar
    },
    createCookie(name, value, expirate) {
      var d = new Date();
      d.setTime(d.getTime() + expirate * 24 * 60 * 60 * 1000);
      var expires = 'expires=' + d.toUTCString();
      this.$cookies.set(name, value, expires);
    }

    // checkCookie(keyName) {
    //   let confirmCookie = this.$cookies.get(keyName);

    //   if (!confirmCookie) {
    //     this.createCookie(keyName, 1, 365);
    //     this.$store.dispatch("setNewVisit");
    //   } else {
    //     this.$store.dispatch("setConcurrentVisit");
    //   }
    // },

    // createCookie(name, value, expirate) {
    //   var d = new Date();
    //   d.setTime(d.getTime() + expirate * 24 * 60 * 60 * 1000);
    //   var expires = "expires=" + d.toUTCString();
    //   //create
    //   this.$cookies.set(name, value, expires);
    // },
  },
  created() {
    var self = this;
    let state = this.$store.state;

    this.$store.dispatch('getInfoCompany');
    this.$store.dispatch('getCompanyMetadata');

    setTimeout(() => {
      this.loader = false
    }, 2000);

    //Visits Control
    if (process.env.NODE_ENV === 'production') {
      this.checkCookie('countVisit');
      window.addEventListener('unload', function () {
        if (!navigator.sendBeacon) return;

        let url = state.API_URL;
        // Create the data to send
        var data = {
          companyid: self.$store.state.ID_COMPANY,
          live: '0'
        };
        //var data = "companyid=" + self.$store.state.ID_COMPANY + "&live=0" ;
        var blob = new Blob([JSON.stringify(data)], {
          type: 'application/json'
        });

        // Send the beacon
        navigator.sendBeacon(url, blob);

        // Log the data and result
        //console.log("sendBeacon: URL = ", url, "; data = ", data, "; status = ", status);
      });
    }

  }
};
</script>

<style>
.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 32px;
  text-align: center;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.2);
  background-color: #222a369e;
  backdrop-filter: blur(5px);
  color: #fff;
  z-index: 90000000;
}
.watermark {
  /*background:transparent url('./assets/images/logoImmo/immoCRMLogo.png') no-repeat;
    */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  object-position: center;
  position: absolute;
  width: 100%;
}

.watermark {
  opacity: 0.9;
}
</style>