<template>
  <main>
    <Preloader v-if="!prop" />
    <div v-else>
      <!-- GENERAL CONTAINER -->
      <section class="ftco-section contact-section bg-light ">
        <div class="container">
          <div class="row">
            <!-- Detail Section -->
            <div class="col-lg-8 bg-white p-5 mb-5">
              <!-- SECCION A -->
              
              <!-- CAROUSEL -->
              <DetailCarousel :items="prop.images" />
              <!-- Best Information & Prices -->
              <div class="ftco-animate fadeInUp ftco-animated">
                <!-- Address -->
                <div class="border-bottom">
                  <h2>{{ prop.propertyNameEs }}</h2>
                  <span v-if="prop.address != '0'">
                    <p class="grey">
                      <i class="fa fa-map-marker-alt"></i> {{prop.address}}
                    </p>
                    <p class="color-page" v-if="prop.city != '0' || prop.state != '0' "> 
                      {{ prop.city }} , {{ prop.state }} </p>
                  
                  </span>
                </div>

                <!-- Prices -->
                <div class="mt-2 prices border-bottom">
                  <div class="row ">
                    <div
                      v-if="prop.operation.opportunity_sold != 0"
                      class="col-md-6 border-bottom"
                    >
                      <h5>
                        <b>
                          {{ prop.operation.opportunity_sold }}
                        </b>
                      </h5>
                      <strong class=" text-success h1 mb-3">
                        {{ prop.prices.opportunity_sold }}
                      </strong>
                    </div>
                    <div
                      v-if="prop.operation.opportunity_rent != 0"
                      class="col-md-6 border-bottom"
                    >
                      <h5>
                        <b> {{ prop.operation.opportunity_rent }} </b>
                      </h5>
                      <strong class="text-success h1 mb-3">
                        {{ prop.prices.opportunity_rent }}
                      </strong>
                    </div>
                  </div>
                  <div class="row">
                    <div v-if="prop.operation.sold != 0" class="col-md-6 ">
                      <h5>
                        <b> {{ prop.operation.sold }} </b>
                      </h5>
                      <strong
                        :class="[
                          prop.operation.opportunity_sold != 0
                            ? 'not-price'
                            : '',
                          'text-success h1 mb-3',
                        ]"
                      >
                        {{ prop.prices.sold }}
                      </strong>
                    </div>
                    <div v-if="prop.operation.rent != 0" class="col-md-6">
                      <h5>
                        <b> {{ prop.operation.rent }} </b>
                      </h5>
                      <strong
                        :class="[
                          prop.operation.opportunity_rent != 0
                            ? 'not-price'
                            : '',
                          'text-success h1 mb-3',
                        ]"
                      >
                        {{ prop.prices.rent }}
                      </strong>
                    </div>
                  </div>
                </div>

                <!-- Best Information -->
                <div class="row border-bottom">
                  <div class="col-md-12">
                    <ul class="row props-ul mt-3 justify-content-center">
                      <li v-if="prop.folio !=0" class="col-lg-4">
                        <span class="prop-specs">
                          {{$t("pages.property_detail.folio")}}
                        </span>
                        <span>
                          <i class="fa fa-map-signs"></i> <b> {{ prop.folio }}</b>
                        </span>
                      </li>
                      <li v-if="prop.bathrooms !=0" class="col-lg-4">
                        <span class="prop-specs">
                          {{$t("pages.property_detail.feature_bathrooms")}}
                        </span>
                        <span>
                          <i class="fa fa-bath"></i> <b> {{ prop.bathrooms }}</b>
                        </span>
                      </li>
                      <li v-if="prop.bedrooms !=0" class="col-lg-4">
                        <span class="prop-specs">
                          {{$t("pages.property_detail.feature_bedrooms")}}
                        </span>
                        <span>
                          <i class="fa fa-bed"></i> <b> {{ prop.bedrooms }}</b>
                        </span>
                      </li>
                      <li v-if="prop.referenceEs !=0" class="col-lg-4">
                        <span class="prop-specs">
                          {{$t("pages.property_detail.references")}}
                        </span>
                        <span>
                          <i class="fa fa-map-signs"></i> <b> {{ prop.referenceEs }}</b>
                        </span>
                      </li>
                      <li v-if="prop.m2c !=0" class="col-lg-4">
                        <span class="prop-specs">
                          {{$t("pages.property_detail.feature_area_lot")}}
                        </span>
                        <span>
                          <i class="fa fa-arrows-alt"></i> <b> {{ prop.m2c }}</b>
                        </span>
                      </li>
                      <li v-if="prop.mConstruccion !=0" class="col-lg-4">
                        <span class="prop-specs">
                          {{$t("pages.property_detail.feature_area_cons")}}
                        </span>
                        <span>
                          <i class="fa fa-arrows-alt"></i> <b> {{ prop.mConstruccion }}</b>
                        </span>
                      </li>
                      <li v-if="prop.nameTower" class="col-lg-4">
                        <span class="prop-specs">
                          {{$t("pages.property_detail.nameTower")}}
                        </span>
                        <span>
                          <b> {{ prop.nameTower }}</b>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

                <!-- Description -->
                <div
                  class="properties-single mt-4 mb-2 ftco-animate fadeInUp ftco-animated border-bottom"
                >
                  <h2 class="text-center">{{$t("pages.property_detail.description_prop")}}</h2>
                    <div v-if="activeLocale == 'es' || !prop.descriptionEn">
                      <p>
                        <pre>{{ prop.descriptionEs }}</pre>
                      </p>
                    </div>
                    <div v-else>
                      <p >
                        <pre>{{ prop.descriptionEn }}</pre>
                      </p>
                    </div>
                </div>

                <!-- Amenities -->
                <div
                  class="properties-single ftco-animate mb-5 mt-4 fadeInUp ftco-animated border-bottom"
                  v-if="
                    prop.interiorAmenities ||
                      prop.otherAmenities ||
                      prop.exteriorAmenities ||
                      prop.services ||
                      prop.developmentServices
                  "
                >
                  <h2 class="text-center">{{$t("pages.property_detail.amenities_prop")}}</h2>
                  <div class=" mt-2 mb-2">
                      <!-- Interios -->
                      <div class="container mb-3" v-if="prop.interiorAmenities || prop.otherAmenities">
                        <div class="row mb-3">
                          <h4 class="h4 text-black">{{$t("pages.property_detail.amenities_interior")}}</h4>
                        </div>
                        <div class="row">
                          <p class="amenidades col-md-4" v-if="prop.interiorAmenities.hall || prop.interiorAmenities.sala">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.int_sala") }}
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.interiorAmenities.kitchen  || prop.interiorAmenities.cocina">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.int_cocina") }}
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.interiorAmenities.dinning_room || prop.interiorAmenities.comedor">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.int_comedor") }}
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.interiorAmenities.service_area || prop.interiorAmenities.area_servicio">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.int_areadeservicio") }}
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.interiorAmenities.amueblado">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.int_amueblado") }}
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.interiorAmenities.cuartoTv">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.int_cuartoTV") }}
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.interiorAmenities.estudio">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.int_estudio") }}
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.interiorAmenities.cocinaIntegral">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.int_cocinaIntegral") }}
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.interiorAmenities.ctoLavado">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.int_ctoLavado") }}
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.interiorAmenities.ctoServBaño">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.int_ctoServBano") }}
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.interiorAmenities.area_servicio">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.int_ctoServBano") }}
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.interiorAmenities.aa">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.int_aa") }}
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.interiorAmenities.minisplit">
                              <i class="fas fa-check"></i>
                              Mini split
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.interiorAmenities.ventiladores">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.int_ventiladores") }}
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.interiorAmenities.calentadorAgua">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.int_calentadorAgua") }}
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.interiorAmenities.casaInteligente">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.int_casaInteligente") }}
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.interiorAmenities.microondas">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.int_microondas") }} 
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.interiorAmenities.lavaPlatos">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.int_lavaPlatos") }} 
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.interiorAmenities.camaraFria">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.int_camaraFria") }} 
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.interiorAmenities.central">
                              <i class="fas fa-check"></i>
                              Central
                          </p>
                        </div>
                      </div>

                      <div class="container mb-3" v-if="prop.exteriorAmenities">
                        <div class="row mb-3">
                          <h4 class="h4 text-black"> {{$t("pages.property_detail.amenities_exterior")}}</h4>
                        </div>
                        <div class="row">
                          <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.balcon">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.ext_balcon") }}
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.terraza">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.ext_terraza") }}
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.jardin">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.ext_jardin") }}
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.alberca">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.ext_alberca") }}
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.elevador">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.ext_elevador") }}
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.estacionamiento">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.ext_estacionamiento") }}
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.bbq">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.ext_bbq") }}
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.bar">
                              <i class="fas fa-check"></i>
                              Bar
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.gimnasio">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.ext_gimnasio") }}
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.muelle">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.ext_muelle") }} 
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.cisterna">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.ext_cisterna") }}
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.jacuzzi">
                              <i class="fas fa-check"></i>
                              Jacuzzi
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.plantaElectrica">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.ext_plantaElectrica") }} 
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.cercoElectrico">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.ext_cercoElectrico") }}
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.puertaMuLock">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.ext_puertaMuLock") }}
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.almacen">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.ext_almacen") }}
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.panelesSolares">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.ext_panelesSolares") }}
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.areaManiobras">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.ext_areaManiobras") }} 
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.casetaSeguridad">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.ext_casetaSeguridad") }}
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.cortinasMetalicas">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.ext_cortinasMetalicas") }}
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.areaDescarga">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.ext_areaDescarga") }}
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.cortinasAnticiclonicas">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.ext_cortinasAnticiclonicas") }}
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.techado">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.ext_techado") }}
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.solarium">
                              <i class="fas fa-check"></i>
                              Solarium
                          </p>
                          <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.patio_servicio">
                              <i class="fas fa-check"></i>
                              {{ $t("pages.amenities.ext_patioservicio") }}
                          </p>
                        </div>
                      </div>

                    <div class="container mb-3" v-if="prop.developmentAmenities">
                      <div class="row mb-3">
                        <h4 class="h4 text-black"> {{$t("pages.property_detail.amenities_maintenance")}}</h4>
                      </div>
                      <div class="row">
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.condominio">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_condominio") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.cafetera">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_cafetera") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.clubGolf">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_clubGolf") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.casaClub">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_casaClub") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.alberca_d">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_alberca_d") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.muelle_d">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_muelle_d") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.clubPlaya">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_clubPlaya") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.spa">
                            <i class="fas fa-check"></i>
                            SPA
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.gimnasio_d">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_gimnasio_d") }} 
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.canchaTenis">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_canchaTenis") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.juegosInf">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_juegosInf") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.lobby">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_lobby") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.elevador_d">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_elevador_d") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.salonEventos">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_salonEventos") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.restaurante">
                            <i class="fas fa-check"></i>
                          {{ $t("pages.amenities.man_restaurante") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.comercios">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_comercios") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.bodega">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_bodega") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.seguridad">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_seguridad") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.accesoControlado">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_accesoControlado") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.servicioAdmin">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_servicioAdmin") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.centroNegocios">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_centroNegocios") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.albercaAlquiler">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_albercaAlquiler") }} 
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.servConcierge">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_servConcierge") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.otherServices">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_otherServices") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.m2constructionDev">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_m2constructionDev") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.m2greenArea">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_m2greenArea") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.palapa">
                            <i class="fas fa-check"></i>
                            Palapa
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentAmenities.marina">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.man_marina") }} 
                        </p>
                      </div>

                    </div>

                    <div class="container mb-3" v-if="prop.developmentServices || prop.services">
                      <div class="row mb-3">
                        <h4 class="h4 text-black"> {{$t("pages.property_detail.services_prop")}}</h4>
                      </div>
                      <div class="row">
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.mantenimiento">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_mantenimiento") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.servTransp">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_servTransp") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.tour">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_tour") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.tiendaComestibles">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_tiendaComestibles") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.servLimpieza">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_servLimpieza") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.servAgua">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_servAgua") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.servLocalCel">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_servLocalCel") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.rampasSillasRuedas">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_rampasSillasRuedas") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.luz">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_luz") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.agua">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_agua") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.gas">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_gas") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.circuitoCerrado">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_circuitoCerrado") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.energiaSustentable">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_energiaSustentable") }}
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.alumbrado">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_alumbrado") }} 
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.cableTV">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_cableTV") }} 
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.wifi">
                            <i class="fas fa-check"></i>
                            Wi-Fi
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.concierge">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_concierge") }} 
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.mascotas">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_mascotas") }} 
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.fumar">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_fumar") }} 
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.sistemaDeAlarma">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_sistemaDeAlarma") }} 
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.servTelefono">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_servTelefono") }} 
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.camarasDeSeguridad">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_camarasDeSeguridad") }} 
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.drenaje">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_drenaje") }} 
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.pavimento">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_pavimento") }} 
                        </p>
                        <p class="amenidades col-md-4" v-if="prop.developmentServices.poolRentas">
                            <i class="fas fa-check"></i>
                            {{ $t("pages.amenities.ser_poolRentas") }} 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Map -->
                <section
                  class="properties-single ftco-animate mb-5 mt-4 fadeInUp ftco-animated"
                  v-if="prop.latitude || prop.longitude"
                >
                  <div class="col-md-12 no-gutters mt-3">
                    <div class="col-12">
                      <h2 class="text-center mb-5">
                        {{ $t("pages.property_detail.map_title") }}
                      </h2>
                    </div>
                    <div class="col-12">
                      <GoogleMap
                        :mapLat="prop.latitude"
                        :mapLng="prop.longitude"
                      />
                    </div>
                  </div>
                </section>

                <!-- Video -->
                <section
                  class="properties-single ftco-animate mb-5 mt-4 fadeInUp ftco-animated"
                  v-if="prop.video"
                >
                  <div class="col-md-12 no-gutters mt-3">
                    <div class="col-12">
                      <h4 class="text-black mb-3">
                        Video
                      </h4>
                    </div>
                    <div class="col-12">
                      <iframe
                        width="100%"
                        height="315"
                        :src="prop.video"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      >
                      </iframe>
                    </div>
                  </div>
                </section>
              </div>
            </div>

            <!-- Widget Section -->
            <div class="col-lg-4">
              <!-- Main Contact Information -->
              <div class="ftco-section contact-section bg-white">
                <div class="bg-white" style="text-align: center">
                  <img
                    class="img img-age"
                    :src="
                      prop.agent.image ? prop.agent.image : '/images/noimage.png'
                    "
                    :alt="`${prop.agent.name}-img`"
                  />
                  <hr />
                  <p class="mb-0">
                    <!-- <b class="texto2"><i class="icon-envelope-open texto2"></i> :</b>-->
                    <a class="contact-links"> {{ prop.agent.name }}</a>
                  </p>
                  <p class="mb-0">
                    <b class="texto2"
                      ><i class="icon-phone texto2"></i> /
                      <i class="icon-whatsapp"></i> :</b
                    >
                    <a
                      class="contact-links"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Contactar en WhatsApp"
                      target="_blank"
                      :href="
                        `https://api.whatsapp.com/send?text=${$t(
                          'pages.property_detail.share_whats_message_agent'
                        )}: ${url}&phone=52${prop.agent.cellphone}`
                      "
                    >
                      {{ prop.agent.cellphone }}
                    </a>
                  </p>
                  <p class="mb-4 ">
                    <b class="texto2"
                      ><i class="icon-envelope-open texto2"></i> :</b
                    >
                    <a class="contact-links"> {{ prop.agent.email }}</a>
                  </p>
                </div>
                <!-- Contact Form -->
                <div class="bg-white rounded ">
                  <ContactForm />
                </div>
              </div>

              <!-- Download Files -->
              <div
                class="properties-single ftco-animate mb-5 mt-4 fadeInUp ftco-animated bg-white"
              >
                <section class="p-5">
                  <div class="row text-center border-bottom mb-3">
                    <h2 class="text-center h2-widget">{{ $t("pages.property_detail.share_title") }}</h2>
                  </div>
                  <div>
                    <h6 class="text-black">{{ $t("pages.property_detail.banners") }}</h6>
                    <ul
                      class="buttons-content banner row justify-content-center"
                    >
                      <li v-if="prop.banner1" class="banners col-md-6">
                        <a
                          class="banner-button"
                          target="_blank"
                          :href="prop.banner1"
                        >
                          <i class="icon-file-image-o"></i> 1 Foto
                        </a>
                      </li>
                      <li v-if="prop.banner3" class="banners col-md-6">
                        <a
                          class="banner-button"
                          target="_blank"
                          :href="prop.banner3"
                        >
                          <i class="icon-file-image-o"></i> 3 Fotos
                        </a>
                      </li>
                      <li v-if="prop.banner4" class="banners col-md-6">
                        <a
                          class="banner-button"
                          target="_blank"
                          :href="prop.banner4"
                        >
                          <i class="icon-file-image-o"></i> 4 Fotos
                        </a>
                      </li>
                      <li v-if="prop.banner6" class="banners col-md-6">
                        <a
                          class="banner-button"
                          target="_blank"
                          :href="prop.banner6"
                        >
                          <i class="icon-file-image-o"></i> 6 Fotos
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div v-if="prop.flyerEs && prop.flyerEn">
                    <h6 class="text-black">{{ $t("pages.property_detail.flyers") }}</h6>
                    <ul
                      class="buttons-content banner row justify-content-center"
                    >
                      <li v-if="prop.flyerEs" class="banners col-md-12">
                        <a
                          class="banner-button"
                          target="_blank"
                          :href="prop.flyerEs"
                        >
                          <i class="icon-file-pdf-o"></i> FLYER ESPAÑOL
                        </a>
                      </li>
                      <li v-if="prop.flyerEn" class="banners col-md-12">
                        <a
                          class="banner-button"
                          target="_blank"
                          :href="prop.flyerEn"
                        >
                          <i class="icon-file-pdf-o"></i> ENGLISH FLYER
                        </a>
                      </li>
                    </ul>
                  </div>
                </section>
              </div>

              <!-- Share -->
              <div class="properties-single ftco-animate mb-5 mt-4 fadeInUp ftco-animated bg-white">
                <section class="p-5">
                  <div class="text-center border-bottom mb-3">
                    <h2 class="text-center h2-widget">
                      {{ $t("pages.property_detail.share_prop") }}</h2>
                  </div>
                  <div class="row py-3 site-foote justify-content-center">
                    <a
                      :href="
                        `https://www.facebook.com/sharer/sharer.php?u=${url}`
                      "
                      target="_blank"
                      class="pl-3 pr-3"
                    >
                      <span class="icon-facebook-f"></span>
                    </a>
                    <a
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Compartir por Twitter"
                      :href="`https://twitter.com/home?status=${url}`"
                      target="_blank"
                      class="pl-3 pr-3"
                    >
                      <span class="icon-twitter1"></span>
                    </a>
                    <a
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Compartir por WhatsApp"
                      :href="
                        `https://api.whatsapp.com/send?text=${$t(
                          'pages.property_detail.share_whats_message_public'
                        )}: ${url}`
                      "
                      class="pl-3 pr-3"
                      target="_blank"
                    >
                      <span class="icon-whatsapp"></span>
                    </a>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import DetailCarousel from "@/components/carousel/DetailCarousel.vue";
import Banner from "@/components/layout/Banner.vue";
import Preloader from "@/components/layout/Preloader.vue";
import SearchForm from "@/components/search/SearchForm.vue";
import ContactForm from "@/components/layout/ContactForm.vue";
import GoogleMap from "@/components/layout/GoogleMap.vue";
export default {
  components: {
    Banner,
    Preloader,
    GoogleMap,
    SearchForm,
    ContactForm,
    DetailCarousel,
  },
  data() {
    return {
      folio: "",
      agentId: 0,
      url: "",
      amenities: [],
    };
  },
  computed: {
    ...mapGetters({
      prop: "_getProperty",
    }),
    activeLocale() {
      return this.$i18n.locale;
    },
    format(val) {
      let value = val.prop.bestprice[0];
      //Formateo de decimales
      let decimalPrice = value.lastIndexOf(".");
      //Extaracción de moneda
      let currentPriceM = value.indexOf("MXN");
      let currentPriceU = value.indexOf("USD");
      // Precio formateado value.substr(currentPrice)
      let formatPrice = value.substr(0, decimalPrice) + " ";
      if (currentPriceM != "-1") {
        formatPrice += value.substr(currentPriceM);
      } else {
        formatPrice += value.substr(currentPriceU);
      }
      return formatPrice.slice(1);
    },
  },
  created() {
    this.folio = this.$route.params.folio;
    if (this.$route.params.agentId) {
      this.agentId = this.$route.params.agentId;
    }
    let data = {
      folio: this.folio,
      agent: this.agentId,
    };
    //call metadatas for property
    // this.$store.dispatch("getPropertyMetadata", this.folio);
    // end call metadatas for property
    this.$store.dispatch("getProperty", data);
    this.url = this.$store.state.URL_SITE + this.$route.path;
    //Send Data to Access
    if (process.env.NODE_ENV === "production") {
      this.$getLocation().then((coordinates) => {
        let accessData = {
          lat: coordinates.lat,
          lng: coordinates.lng,
          url: this.url,
          propertyId: this.folio,
        };
        this.$store.dispatch("setPropertyClick", accessData);
      });
    }
    this.sliderStyle();
  },
  methods: {
    sliderStyle() {
      setTimeout(function() {
        /*----------------------------------------------------*/
        /*  owlCarousel
					/*----------------------------------------------------*/
        if ($(".nonloop-block-13").length > 0) {
          $(".nonloop-block-13").owlCarousel({
            center: false,
            items: 1,
            loop: true,
            stagePadding: 0,
            autoplay: true,
            margin: 20,
            nav: false,
            dots: true,
            navText: [
              '<span class="icon-arrow_back">',
              '<span class="icon-arrow_forward">',
            ],
            responsive: {
              600: {
                margin: 20,
                stagePadding: 0,
                items: 1,
              },
              1000: {
                margin: 20,
                stagePadding: 0,
                items: 2,
              },
              1200: {
                margin: 20,
                stagePadding: 0,
                items: 3,
              },
            },
          });
        }
        if ($(".slide-one-item").length > 0) {
          $(".slide-one-item").owlCarousel({
            center: false,
            items: 1,
            loop: true,
            stagePadding: 0,
            margin: 0,
            autoplay: true,
            pauseOnHover: false,
            nav: true,
            animateOut: "fadeOut",
            animateIn: "fadeIn",
            navText: [
              '<span class="icon-arrow_back">',
              '<span class="icon-arrow_forward">',
            ],
          });
        }
        if ($(".nonloop-block-4").length > 0) {
          $(".nonloop-block-4").owlCarousel({
            center: true,
            items: 1,
            loop: false,
            margin: 10,
            nav: true,
            navText: [
              '<span class="icon-arrow_back">',
              '<span class="icon-arrow_forward">',
            ],
            responsive: {
              600: {
                items: 1,
              },
            },
          });
        }
      }, 400);
      return;
    },
  },
  /*--/ 
  updated() {
    Property owl owl 
    $("#property-single-carousel").owlCarousel({
      loop: true,
      margin: 0,
      nav: true,
      navText: [
        '<i class="ion-ios-arrow-back" aria-hidden="true"></i>',
        '<i class="ion-ios-arrow-forward" aria-hidden="true"></i>',
      ],
      responsive: {
        0: {
          items: 1,
        },
      },
    });
  },
  /--*/
};
</script>

<style scoped>
.price {
  background-color: #9cd7d3c5;
  border-radius: 5px;
  width: 50%;
  height: auto;
  text-align: center;
}
.price h3 {
  color: #000;
  padding-top: 10px;
  padding-bottom: 10px;
}
.whatsappButtom {
  color: #000;
}
.fbButton {
  float: right;
}
#leyendaPrecios {
  float: right;
  font-size: 11px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding-bottom: 10px;
}
@media screen and (max-width: 415px) {
  .agentPhoto .email {
    display: none !important;
  }
  .show-more {
    max-height: 2000px !important;
    height: auto !important;
    overflow: initial !important;
    position: relative;
    transition: max-height 1s;
  }
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #c0a44a !important;
  border-color: #c0a44a !important;
}
.text-success {
  color: #6f6f6f !important;
  font-size: 25px;
}

.for-ul {
  column-count: 3 !important;
}
.img-age{
  width: 48% !important;
}
.banner {
  display: block;
  margin-top: 20px !important;
  list-style-type: none;
  padding: 0;
  overflow: hidden;
  text-align: center;
  margin-bottom: 10px;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.banners {
  float: left;
}

.banner-button {
  display: block;
  color: #373737;
  width: auto;
  border: 1px solid #c0a44a;
  border-radius: 20px;
  padding: 7px;
  margin: 5px;
  text-decoration: none;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
}

.banner-button:hover {
  border: 1px solid #c0a44a;
  background: #c0a44a;
  color: #ffff;
}

.buttons-content {
  list-style-type: none;
  padding: 0;
  overflow: hidden;
  text-align: center;
}

.fa-check-square {
    color: #c0a44a;
}

.color-page{
  color: #c0a44a;
}

.props-ul{
  list-style: none;
  padding: 0;
}

i{
  color: #c0a44a;
}

.prop-specs {
    display: block;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: .1em;
}

.grey{
  color: #373737;
}

h4{
  font-size: 20px;
}

.h2-widget{
  text-transform: capitalize;
  font-size: 18px;
}
</style>
